<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <v-card
        v-if="
          userid == 1
            ? true
            : (lista_tf_arqs[lista_tf_arqs.length - 1].status != 1) &
              (lista_tf_arqs[lista_tf_arqs.length - 1].usuario_id == usuid)
        "
        elevation="8"
        class="mt-1"
      >
        <v-card-title class="card-header">
          <h3 class="mb-0">
            <h3 class="mb-0">Arquivos Protocolo</h3>
          </h3></v-card-title
        >
        <div class="card-body">
          <div class="col-md-12">
            <div class="form">
              <div class="row form-group">
                <InputFile text="Documentos Protocolo" ref="principal" />

                <div class="col-md-12">
                  <label for="porcentagem">Descrição:</label>
                  <textarea
                    required=""
                    type="text"
                    class="form-control"
                    v-model="form.descricao"
                    placeholder="Digite a Descrição"
                  ></textarea>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-12 text-center">
                  <v-btn
                    color="primary"
                    elevation="2"
                    :loading="verif"
                    @click.prevent="
                      confirm('cria')
                    "
                    class="btn btn-primary"
                    :disabled="verif"
                    >{{ 'cria'}}</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card v-if="lista_arqs != ''" elevation="10" class="mt-4 p-2">
        <h3 v-if="lista_arqs != ''" class="text-uppercase">
          Documentos de criação do protocolo:
        </h3>
        <v-sheet class="mx-auto pt-5" max-width="700">
          <v-slide-group mandatory show-arrows>
            <v-slide-item
              v-for="(lista, j) in lista_arqs"
              :key="j"
              v-slot="{ active, toggle }"
            >
              <v-btn
                class="mx-2"
                :input-value="active"
                active-class="success white--text"
                depressed
                rounded
                @click="toggle"
                @click.prevent="ircon(lista.id)"
              >
                {{ lista.nome_original }}</v-btn
              >
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-card>

      <div v-for="(tf, k) in lista_tf_arqs" :key="k">
        <v-card class="mt-5" elevation="5" v-if="tf.nome_sobrenome">
          <div class="col-md-12 font-weight">
            <div class="">
              <span class="h4 col-12" v-if="tf.nome_sobrenome">
                {{ tf.nome_sobrenome2 }} Transferiu protocolo para
                {{ tf.nome_sobrenome }}
              </span>
              <!-- <span  class=" h3  col-12" v-if="!lista_tf_arqs[k-1].nome_sobrenome"> Aceitou Transferencia de Protocolo {{tf.nome_sobrenome}}</span> -->
              <br />
              <span
                style="font-size: 14px"
                v-if="tf.descricao != null"
                class="col-12"
              >
                Descrição da Transferência : {{ tf.descricao }}</span
              >
              <span
                style="font-size: 14px"
                v-if="tf.descricao == null"
                class="col-12"
                v-show="k != 0"
              >
                Descrição da Transferência : não ha descrição</span
              >
            </div>

            <!-- <v-sheet
              class="mx-auto pt-5 text-center"
              max-width="700"
              height="300"
              elevation="12"
              rounded="lg"
              width="100%"
            >
              <v-slide-group mandatory show-arrows>
                <v-slide-item
                  v-for="(lista, j) in tf.arquivos"
                  :key="j"
                  v-slot="{ active, toggle }"
                >
                  <v-card
                    style="background-color: #3699ff"
                    active-class="success white--text"
                    :input-value="active"
                  >
                    <div style="background-color: #ffffff">
                      Descrição : {{ lista.descricao }}
                    </div>
                    <v-btn
                      class="text-white"
                      small
                      outlined
                      @click="toggle"
                      @click.prevent="ircon(lista.id)"
                    >
                      {{ lista.nome_original }}</v-btn
                    >

                    <v-btn
                      v-if="
                        userid == 1
                          ? true
                          : lista.usuario_id == usuid
                          ? true
                          : false
                      "
                      small
                      :input-value="active"
                      active-class="error white--text"
                      @click="toggle"
                      icon
                      @click.prevent="confirm('excluir', lista)"
                    >
                      <i class="fas fa-trash-alt"></i
                    ></v-btn>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet> -->
          </div>
        </v-card>
        <v-icon
          v-if="tf.arquivos"
          color="black"
          size="34"
          v-text="'mdi-arrow-down-bold'"
        ></v-icon>
        <v-list
          v-if="tf.arquivos"
          class="mx-auto mt-4 p-1"
          elevation="8"
          max-width="100%"
        >
          <v-icon
            color="black"
            size="34"
            v-text="'mdi-arrow-bottom-right'"
          ></v-icon>
          <h5 class="pl-5 ml-3">
            Documentos anexados por {{ tf.nome_sobrenome }}
          </h5>
          <!-- <h5 v-else class="pl-5 ml-3">
            Nenhum arquivo anexado por {{ tf.nome_sobrenome }}
          </h5> -->
          <v-list-item-group v-model="model[k]">
            <v-list-item
              v-for="(lista, j) in tf.arquivos"
              :key="j"
              v-slot:default="{ active }"
              color="white"
            >
              <v-card
                :color="active ? 'white' : '#d3d3d3'"
                class="ma-2 pl-3"
                height="83"
                width="100%"
                shaped
                elevation="5"
                @click="toggle"
                @click.prevent="pegararquivo(lista, k)"
              >
                <v-row class="">
                  <v-col md="6" class="">
                    <p>Data de Anexo {{ lista.created_at | moment }}</p>
                  </v-col>
                  <v-col md="6" class="text-right">
                    <v-icon
                      v-if="active"
                      color="black "
                      size="30"
                      v-text="'mdi-close-circle-outline'"
                    ></v-icon>
                  </v-col>
                </v-row>

                <v-btn
                  class="text-black"
                  color="success"
                  small
                  :disabled="!active"
                  @click="toggle"
                  @click.prevent="ircon(lista.id)"
                >
                  {{ lista.nome_original }}
                </v-btn>
                <v-btn
                  v-if="
                    userid == 1
                      ? true
                      : lista.usuario_id == usuid
                      ? true
                      : false
                  "
                  :disabled="!active"
                  small
                  :input-value="active"
                  active-class="error"
                  class="ml-1"
                  @click="toggle"
                  icon
                  @click.prevent="confirm('excluir', lista)"
                >
                  <i class="text-white fas fa-trash-alt"></i
                ></v-btn>
                <p>Descrição : {{ lista.descricao }}</p>
              </v-card>
            </v-list-item>
          </v-list-item-group>
          <v-expand-transition>
            <v-sheet v-if="model[k] != null" height="80" tile>
              <v-row class="fill-height" align="center" justify="center">
                <h6 class="text-h6 text-center col-12">
                  Selecionado {{ model[k] + 1 }}° Arquivo­
                  <br />
                  Descrição: {{ arquivoselect[k].descricao }}
                </h6>
              </v-row>
            </v-sheet>
          </v-expand-transition>
        </v-list>

        <i
          v-if="lista_tf_arqs.length - 1 != k"
          style="font-size: 30px"
          class="fas fa-arrow-circle-down pt-3 mt-5 text-success"
        ></i>
     
      </div>
      <v-card class="mt-5">
        <div class="text-center w-100">
          <v-tooltip
            v-for="(btn, index) in lista_btn"
            :key="index"
            top
            :color="btn.color"
          >
            <template
              v-if="
                true
                // item.protocolo.status == 2 ? true : item.protocolo.status == 1 & item.usuario_id == usuid  ? true: false
              "
              v-slot:activator="{ on, attrs }"
            >
              <template>
                <template  v-if="condicoes(usuid, btn,  lista_tf_arqs[lista_tf_arqs.length - 1])">
                  <v-btn
                    v-if="lista_permissoes_filial[btn.permisao]"
                    fab
                    v-show="true"
                    outlined
                    class="mr-1"
                    @click="acoes( btn.button)"
                    :color="btn.color"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i :class="btn.icon"></i>
                  </v-btn>
                </template>
              </template>
            </template>

            <span>{{ btn.title }}</span>
          </v-tooltip>
        </div>
      </v-card>

   
    </div>
  </div>
</template>
  
  <script>
import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

import InputFile from "@/core/helpers/InputFileMult";
import JwtService from "@/core/services/jwt.service";
import UsuarioService from "@/core/services/usuario.service";
import moment from "moment";
export default {
  components: { InputFile },
  mixins: [searchAddressMixin, fireAlert],
  data() {
    return {
      lista_btn: [
        {
          permisao: "u_Usuario",
          button: "editar",
          title: "Editar registro",
          color: "info",
          icon: "far fa-edit text-primary",
        },
        {
          permisao: "lock_Usuario",
          button: "exclusao",
          title: "Bloquear/Desbloquear registro",
          color: "error",
          icon: "fas fa-unlock-alt text-danger",
        },
        {
          permisao: "u_Usuario",
          button: "tf",
          title: "Transferência Protocolo",
          color: "warning",
          icon: "fas fa-exchange-alt text-warning",
        },
      
        {
          permisao: "u_Usuario",
          button: "arquivos",
          title: "Andamento Protocolo",
          color: "primary",
          icon: "fas fa-file-archive text-info ",
        },
      ],
      verif: false,
      model: { 0: null },
      form: {
        arquivos_id: null,
        tf_protocolo_id: null,
      },
      arquivos: [],

      value: "",
      loading: false,
      host: process.env.VUE_APP_API_URL + "/arquivo/doc/",
      token: JwtService.getToken(),
      userid: UsuarioService.getUsuario()["id"],
      usuid: UsuarioService.getUsuario()["usuario_id"],
      arquivoselect: { 0: null },
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY, H:mm:ss ");
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Arquivos Protocolo" }]);
  },
  computed: {
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_usuarios() {
      return this.$store.state.setor.lista_usuarios.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    mensagem_alert() {
      return this.$store.state.arqtf.mensagem_alert;
    },

    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    lista_tf_arqs() {
      return this.$store.state.arqtf.lista_tf_arqs;
    },
    lista_arqs() {
      return this.$store.state.arqtf.lista_arqs;
    },
    protocolo() {
      return this.$store.state.protocolo.protocolo;
    },
    
  },
  created() {
    this.preenxerCampor();
  },
  methods: {
    pegararquivo(value, k) {
      this.arquivoselect[k] = value;
    },
    async confirm(tipo, value) {
      await this.$confirm({
        title: "Tem Certeza?",
        message: `Esta ação  ` + tipo + ` um anexo desse tramite no protocolo?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
            if (tipo == "excluir") this.exclusao(value);
          }
        },
      });
    },
    async create() {
      if (this.$refs.principal) {
        this.arquivos = await this.$refs.principal.submit();

        this.form.arquivos_id = this.arquivos;
      }
      this.form.tf_protocolo_id = this.$route.params.tf_protocolo_id;

      this.verif = true;
      await this.$store.dispatch("arqtf/create_tf_arq", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
      });
      this.preenxerCampor();
      this.form.arquivos_id = null
      this.form.descricao = null


    },
    // onInput(value) {
    //   let usuarios = Object.values(
    //     this.$store.state.configUsuarios.lista_usuarios
    //   );
    //   usuarios = usuarios.filter((use) => use.id === value);
    //   console.log(usuarios[0]);
    //   this.cpf_cnpj1 = usuarios[0].cpf;
    // },
    async update() {
      this.verif = true;
      await this.$store.dispatch("arqtf/update_tf_arq", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
      
      });
      this.preenxerCampor();

    },

    async preenxerCampor() {
      await this.$store.dispatch(
        "arqtf/listar_arqs",
        this.$route.params.protocolo_id
      );
      await this.$store.dispatch(
        "arqtf/listar_tf_arqs",
        this.$route.params.protocolo_id
      );
      await this.$store.dispatch(
        "protocolo/listar_protocolo",
        this.$route.params.protocolo_id
      );
      
    },

    ircon(value) {
      window.open(this.host + value + "?token=" + this.token, "_blank");
    },
    async exclusao(value) {
      // this.verif = true;
      await this.$store.dispatch("arqtf/delete_tf_arq", value);
      this.fireAlert({
        ...this.mensagem_alert,
      });
      // this.verif = false;
      this.preenxerCampor();
    },
    condicoes(usuid, btn, item) {
      return 1 == usuid
        ? true
       
      
        :  (item.usuario_id == usuid) & (btn.button != "arquivos")
        ? true
        :false
      
     
    },
    sleep: function (milliseconds) {
      var start = new Date().getTime();
      for (var i = 0; i < 1e7; i++) {
        if (new Date().getTime() - start > milliseconds) {
          break;
        }
      }
    },
    async acoes( tipo) {
      if (tipo == "editar") this.atualizar(this.protocolo);
      if (tipo == "tf") {
        this.$router.push({
          name: "tranferenciaProtocolo",
          params: { protocolo_id: this.protocolo.protocolo.id },
        });
      }
  

    
      if (tipo == "exclusao") this.confirm2(this.protocolo);
    },
    async confirm2(value) {
      await this.$confirm({
        title: "Tem Certeza?",
        message: `Esta ação faz Exclusao desse Protocolo no sistema?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    atualizar(value) {
      console.log({value})
      this.$store.dispatch("configEmpresa/atualizar",value);
      this.$router.push({ name: "createProtocolo" });
    },
    async bloquear(value) {
      await this.$store.dispatch("protocolo/delete_protocolo", value);
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "dashboard",

      });
    },
  },
};
</script>
  
  <style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>